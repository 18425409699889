.secretariado-title {
  color: #4f4f4f;
  font-size: 32px;
}

.secretariado-info-message {
  color: #4f4f4f;
}

/* .email-secretariado-input {
  
} */

.vincular-email-button {
  background-color: #e98015;
  color: white;
  width: 184px;
  height: 32px;
}

.vincular-email-button:hover {
  transform: scale(1.1);
}

@media (min-width: 836px ) {
  .secretariado-title {
    padding-top: 5rem;
  }

}

@media (max-width: 836px) {

  .secretariado-info-message  {
    font-size: 16px;
  }
  
  .trash-button {
    width: 1.3rem;
    height: 1.3rem;
  }
  .email-secretariado-input::placeholder {
    font-size: 16px;
    
  }

  .email-secretariado-input {
    height: 38px !important;
    
  }

  .vincular-email-button {
    background-color: #e98015;
    color: white;
    width: 130px;
    height: 28px;
    font-size: 14px !important;
  }
}

@media (max-width: 436px) {
  
  .email-secretariado-input {
    max-width: 300px;
  
  }
}