@media (max-width: 832px) {
  .input-form-login {
    color: var(--white) !important;
    font-weight: 600 !important;
    /* font-size: 24px !important; */
  }
  .input-form-login-wrapper {
    width: 100vw;
    padding: 0 20px !important;
    overflow: auto;
  }
  .button-form-submit {
    background: var(--primary-blue-default);
    margin-top: -24px;
  }
  .input-form-label {
    color: var(--white);
  }
  .patient {
    color: black !important;
  }
  .input-form-compound-label {
    color: var(--white);
  }
  .input-form-input {
    background-color: white;
  }
  .input-form-compound-input {
    background-color: white;
  }
}
@media (max-width: 414px) {
  .input-form-login {
    color: var(--white) !important;
    font-weight: 600 !important;
    /* font-size: 24px !important; */
  }
  .input-form-login-wrapper {
    width: 100vw;
    padding: 0 20px !important;
    overflow: auto;
  }
  .button-form-submit {
    background: var(--primary-blue-default) !important;
    margin-top: -24px;
  }
  .button-form-submit.mobile-pendentes-checklist-button {
    background: var(--primary-blue-default) !important;
  }
  .input-form-label {
    color: var(--white);
  }
  .input-form-compound-label {
    color: var(--white);
  }
  .input-form-input {
    background-color: white;
  }
  .input-form-compound-input {
    background-color: white;
  }
}