.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  height: 32px;
  color: var(--neutral-500);
  border-radius: 16px;
  margin: 0 0 50px;
  padding: 0;
  width: 20.5rem;
  text-align: center;
  overflow: hidden;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}

.react-tabs__tab {
  display: inline-block;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
}

.react-tabs__tab--selected {
  background: var(--primary-blue-default);
  border-color: #aaa;
  color: #fff;
}

.react-tabs__tab--disabled {
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
