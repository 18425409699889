.modalConfirmacao {
  width: 390px;
  /* min-height: 219px; */
  color: rgb(235, 87, 87);
  padding: 1rem;
}

.backgroundClass  {
  background-color: rgb(235, 87, 87);
}


.pendenteBackGroundClass {
  background-color: rgb(239, 117, 23)
}

.minhaEquipe-btn {
  max-width: 110px !important;
}

.min-button-width {
  min-width: 82px;
}
.info-text-modal {
  padding: 0.5rem;
}

@media (min-width: 837px) {
  .solicitacao-buttons {
    width: 110px;
  }
}

@media (max-width: 836px) {
  .minhaEquipe-btn {
    max-width: 90px !important;
    font-size: 14px;  
}
.modalConfirmacao {
  /* width: auto; */
  min-height: auto;
  padding: 0.75rem;

}

.mobile-button-container {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.info-text-modal {
  font-size: 16px;
}

}

@media (max-width: 375px) {
  .minhaEquipe-btn {
    max-width: 90px !important;
    font-size: 12px;  
}
.modalConfirmacao {
  width: auto;
  min-height: auto;
  padding: 0.75rem;

}

.info-text-modal {
  font-size: 16px;
}

}