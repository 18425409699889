div .modalBuscarMedico {
  width: 500px;
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::placeholder {
  font-size: 18px;
}

h3 {
  color: rgb(39, 40, 72)
}

.buttonPendente {
  background-color: rgb(239, 117, 23);
}

.buttonEmEquipe {
  background-color: rgb(179, 179, 179);
}

.no-wrap {
  white-space: nowrap;
}

@media (max-width: 836px) {
  .adicionar-btn {
    font-size: 12px;
    /* padding: 0.25rem 0.25rem !important */
  }

  .text-confirmar {
    font-size: 14px;
    text-align: center;
    padding-inline: 8px;
  }

  .input-buscar {
    font-size: 14px;
    height: 35px;
  }

  .input-buscar::placeholder {
    font-size: 14px
    
  }


  .cidade-modal {
    font-size: 12px;
  }

  .buscar-medico-close-btn {
    right: 30px !important;
    top: -75px !important;
  }

  .buscar-input-container {
    flex-direction: column;
    justify-content: start;
  }

  .title-buscar-medico {
    padding-top: 5px !important;
    justify-content: start;
  }

  div .modalBuscarMedico {
    width: 330px;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .title-buscar-medico {
    margin-top: 5px;
    font-size: 22px !important;
  }

  .modal-lista-container {
    max-width: 290px;
  }
  
  .btn-buscar {
    padding: 0.25rem 0.25rem;
    font-size: 12px;
    align-self: end;
    height: 30px;
    margin-top: 10px;
  }

}