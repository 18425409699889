tr.tr-item {
  transition: 0.2s;
}
tr.tr-item:hover {
  color: var(--primary-blue-default);
  transform: scaleX(0.997);
  transition: 0.2s;
}
tr.tr-item-disabled:hover {
  color: #858585;
  transform: scaleX(0.997);
  transition: 0.2s;
}
span.td-item {
  transition: 0.1s;
  color: var(--primary-blue-default);
  border-radius: 100%;
}
td > span.td-item:hover {
  background-color: var(--primary-blue-default) !important;
  color: white;
  border-radius: 100%;
  padding: 1px;
  transition: 0.1s;
}
