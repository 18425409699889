/* .form-body {
  height: 100%;
  background: var(--white);
  box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  position: relative;
  padding-left: 10px;
} */
.form-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 38px;
  color: var(--primary-blue-default);
  cursor: default;
}
.anx-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  color: var(--primary-blue-default);
}
.form-subtitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: var(--neutral-300);
}
.lb-input {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-100);
}
.form-input-login {
  background: var(--white);
  border: 0.5px solid var(--neutral-400);
  box-sizing: border-box;
  border-radius: 16px;
  width: 300px;
  height: 43.79px;
}
.form-input-sm {
  width: 183.77px;
  height: 43.79px;
  background: var(--white);
  border: 2px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 16px;
}
.btn-input {
  width: 80px;
  height: 80px;
  background: var(--primary-blue-default);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  transition: 0.2s;
}
.btn-input:hover {
  transform: scale(1.05);
  transition: 0.2s;
}
::-webkit-file-upload-button {
  background: var(--primary-blue-default);
  color: var(--white);
  outline: none;
  border: none;
  border-radius: 12px;
  margin-top: 8px;
  margin-right: 50px;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: bold;
}
.btn-calendar {
  position: absolute;
  margin-top: 18px;
  right: 45px;
  color: var(--primary-blue-default);
}
.link-cadastro {
  color: var(--primary-blue-default);
  font-weight: bold;
}
.p-link-cadastro {
  font-size: 18px;
}
.logo-style {
  font-weight: 500;
}
label {
  font-weight: bold;
  color: var(--neutral-400);
}
/* input[type="radio"] + label span {
  width: 18px;
  height: 18px;
  background-color: var(--neutral-400);
  color: var(--neutral-400) !important;
}
input[type="radio"]:checked + label span {
  background-color: white;
  box-shadow: 0px 0px 0px 4px var(--primary-blue-default) inset !important;
  transform: scale(1.05) !important;
  transition: 0.2s !important;
}
input[type="radio"]:checked + label {
  color: var(--primary-blue-default);
} */
textarea:focus,
input:focus {
  outline: none;
  border: 2px solid var(--primary-blue-default);
  transform: scale(1.01);
  transition: 0.1s;
}
/* button:focus {
  outline: none;
  transform: scale(1.05);
  transition: 0.2s;
} */

@media (max-width: 836px) {
  .bg-login {
    display: none;
  }
/* 
  .form-body {
    border-radius: 32px 32px 0px 0px;
    margin-top: -30px;
  }
  .img-mobile {
    margin-left: 0px;
    margin-top: 20px;
    max-width: 450px;
    padding: 10px;
  }
  .bg-img-mobile {
    width: 460px;
  }
  .bg-img-mobile {
    background-color: rgba(84, 193, 251, 0.3);
    margin-top: -25px;
  } */
}
@media (min-width: 800px) and (max-width: 850px) {
  .bg-login {
    display: none;
  }
  /* .form-body {
    box-shadow: none;
    height: 100vh !important;
  }
   .img-mobile {
    margin-left: 0px;
    margin-top: 20px;
    max-width: 450px;
    padding: 10px;
  } 
  .bg-img-mobile {
    width: 460px;
  } 
  .bg-img-mobile {
    background-color: rgba(84, 193, 251, 0.3);
    margin-top: -25px;
  } */
}


@media (max-width: 832px) {
  .login-inputs-container {
    margin: auto !important;
    width: 100vw;
    background: var(--primary-blue-default);
    border-radius: 0 0 32px 32px;
    /* margin: 3rem; */
  }
  .input-senha-container {
    width: 100%;
    padding: 0 20px;
  }
  .forgot-password {
    color: var(--white);
    margin-right: 14px;
  }
  .create-account {
    color: var(--white);
    margin-bottom: 0;
  }
  .link-cadastro {
    color: var(--secondary-yellow-light) !important;
  }
  .login-background {
    background: #2460d7;
    border-radius: 25px 25px 0 0;
  }

  .margin-retrive-password {
    margin-top: 0 !important;
  }
}

@media (max-width: 414px) {
  .login-inputs-container {
    background: var(--primary-blue-default);
  }
  .input-senha-container {
    width: 100%;
    padding: 0 20px;
  }
  .forgot-password {
    color: var(--white);
    margin-right: 14px;
  }
  .create-account {
    color: var(--white);
    margin-bottom: 40px;
  }
  .link-cadastro {
    color: var(--secondary-yellow-light) !important;
  }
}