.button-assinatura-prosseguir {
  background-color: #2460D7;
  color: #FFFF;
  width: 300px;
  height: 53px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-scale:hover {
  transform: scale(1.05)
}

.button-assinatura-reenviar {
  background-color: #FFFF;
  color: #2460D7;
  width: 300px;
  height: 53px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.button-assinatura-disabled {
  background-color: #919090;
  color: #FFFF;
  width: 300px;
  height: 53px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.information-text  {
  color: #4f4f4f;
  font-size: 20px;
  text-align: center;
}

.timer-container {
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.timer-display {
  /* margin-right: 5px; */
  font-weight: 900;
  font-size: 96px;
  text-align: center;
}

.button-assinatura-desativar {
  background-color: #FFFF;
  color: #D72424;
  width: 300px;
  height: 53px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.timer-label {
  font-size: 36px;
  color: #4F4F4F;
  margin-left: 5px;
}
