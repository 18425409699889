.indicator2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -40%) rotate(-180deg) scale(3) !important;
}

.indicator2 svg.svg-loader polyline.poly {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.indicator2 svg.svg-loader polyline.polyline-loader-b#back {
  stroke: rgba(36, 96, 215, 0.2);
}
.indicator2 svg.svg-loader polyline.polyline-loader-f#front {
  stroke: var(--white);
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 48;
  animation: dash 2s linear infinite;
}

@-moz-keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
