.accordion-section {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3px;
}
.accordion-content {
  height: 0px;
  display: none;
  animation: animate 0.2s ease-in-out;
}
/* .accordion-title {
  margin-left: -50px;
} */
.active-accordion {
  height: 100%;
  display: block;
}
.accordion-icon {
  transform: rotate(0deg);
  transition: 0.3s;
}
.accordion-icon-rotate {
  transform: rotate(180deg);
  transition: 0.3s;
  color: var(--primary-blue-default);
}
@media (max-width: 832px) {
  .mobile-accordion-agendamento-container {
    background: var(--white) !important;
  }
}
@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
