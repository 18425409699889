.divNavButtons {
  top: 150px;
  left: 330px;
}

.divAddParticipante {
  top: 200px;
  left: 305px;
  padding-top: 10px;
}
.divPendentes {
  top: 200px;
  left: 305px;
  padding-top: 10px;
}

.titleClass {
  /* line-height: 32x; */
  /* letter-spacing: 1px; */
  margin-left: px;
  font-weight: bold;
  color: rgb(79,79,79);
  padding-left: 20px;
}

.msgClass {
  color: rgb(79,79,79);
  font-size: bold;
  margin-left: 58px;
  margin-top: 15px;
}

#nav-button {
  color: rgb(179, 179, 179)
}

.nav-link {
  text-decoration: none;
  color: rgb(179, 179,179);
  padding: 5px;
}

.equipesClass {
  /* line-height: 32x; */
  /* letter-spacing: 1px; */
  margin-left: 5px;
  font-weight: bold;
  font-size: 24px;
  color: rgb(79,79,79);
  padding-left: 20px;
}

.nomesClass {
  color: rgb(79,79,79);
  font-size: 20px;
}

.pinIcon {
  color: rgb(36, 96, 215);
  font-size: 20px;
}

.p-vertical {
  padding: 10px 0;
  width: 500px;
}

.btn-atualizar {
  width: 100px;
}

.container-medico {
  margin-left: 0.75rem;
}

.btn-new-vendedor-border-x {
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  outline: none !important;
}

.equipe-medica-nav-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0.3rem;
}



@media (max-width: 832px) {
  .mobile-equipe-navigator {
    justify-content: center !important;
    flex-wrap: wrap !important;
    flex-flow: column-reverse;
    padding-bottom: 0.2rem;
  }

  .p-navigator {
    padding-top: 80px;
  }

  .container-medico {
    margin-left: 0.5rem;
  }
  
  .nomesClass {
    font-size: 14px !important;
  }

  .container-lista {
    justify-content: center;
  }

  .p-vertical {
    padding: 10px 0;
    width: 330px;
  }

  .p-12 {
    padding: 2rem !important;
  }
  
  .informative-mobile {
    font-size: 14px;
  }

  .equipe-medica-nav-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
  }
  
  .btn-new-vendedor {
    border: none;
  }

  .btn-atualizar {
    font-size: 12px;
  }

  .text-atualizar {
    font-size: 14px;
  }

  .equipe-medica-nav-container {
    font-size: 14px !important;
    /* text-align: center; */
  }
  
  .btn-new-vendedor-border-x {
    border-left: 1px solid #ccc !important;
    border-right: 1px solid #ccc !important;
    outline: none !important;
  }

  div button.btn-new-vendedor:focus {
    border-radius: 0px;
}
  
}

@media (max-width: 667px) {
  .nomesClass {
    font-size: 14px !important;
  }

  .lista-cidade {
    font-size: 12px !important;
  }

  .p-12 {
    padding: 2rem !important;
  }

  .equipe-medica-nav-container {
    padding: 0.2rem !important;
    font-size: 14px !important;
    /* text-align: center; */

  }

  .equipe-medica-nav {
    font-size: 14px !important;
    /* min-width: 350px !important; */
  }

}

@media (max-width: 400px) {
  .equipe-medica-nav-container {
    /* max-width: 300px; */
}
}