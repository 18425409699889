.DayPicker-wrapper,
.DayPicker-Day {
  outline: none !important;
  transition: 0.1s ease-in;
  font-size: 0.95rem;
  cursor: pointer !important;
}
.DayPicker {
  width: 351px;
  height: 100%;
}
.DayPicker-Day:focus-within {
  border-bottom: 3px solid var(--green-default);
  color: var(--green-default);
  font-weight: bold;
  transition: 0.1s ease-in;
}
span.DayPicker-NavButton--prev,
span.DayPicker-NavButton--next {
  outline: none !important;
  width: 1.5rem;
  height: 1.5rem;
}
div.DayPicker-WeekdaysRow {
  font-weight: bold;
  color: #b5b5b5;
}

.indicator {
  position: absolute!important;
  top: 50%!important;
  left: 48%!important;
  transform: rotate(-180deg) translate(-50%, -50%) scale(4) !important;
}

div input.search-bar-crud {
  border-bottom: 2px!important;
  border-color: #b2b2b2!important;
  border-style: solid!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  transform: scale(1)!important;
}
div input.search-bar-crud:focus {
  border-bottom: 2px!important;
  border-color: var(--primary-blue-default)!important;
  border-style: solid!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  transform: scale(1)!important;
}
