.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.input-label {
  margin-bottom: 5px;
  font-size: 20px;
  color: #4F4F4F;
  font-weight: 300;
}

.input-field {
  width: 360px;
  height: 53px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  background-color: #FFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: 800;
}

.input-field::placeholder {
  font-weight: normal;
}

.input-field:focus {
  outline: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Efeito de foco na sombra */
}