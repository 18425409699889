table.table-autorizacoes {
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  border: 2px solid transparent;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}
table {
  border-collapse: initial;
}
th {
  font-weight: lighter;
  border: 2px solid transparent;
}
tr {
  font-weight: bold;
  border: 2px solid transparent;
  color: var(--neutral-300);
}
td {
  border: 2px solid transparent !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
  /* text-align: left; */
}
.search-icon {
  color: var(--primary-blue-default);
  font-weight: bold;
}
input.search-bar {
  border-bottom: 0.5px;
  border-style: solid;
  border-bottom-color: #b6b6b6;
  border-top: none;
  border-left: none;
  border-right: none;
  color: var(--primary-blue-default);
}
input.search-bar:focus {
  border-bottom: 0.5px;
  border-style: solid;
  border-bottom-color: var(--primary-blue-default);
  border-top: none;
  border-left: none;
  border-right: none;
  transform: scale(1);
  /* border: none; */
}
.filter-autorizacoes {
  height: 50px;
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
span.state-cirurgia {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}
.Autorizada {
  background: #eb5757;
}
.Pendente {
  background: var(--yellow-default);
}
.Agendadas {
  background: var(--green-default);
}
.Concluída {
  background: var(--primary-blue-default);
}
.Desmarcada {
  background: #b6b6b6;
}
.Vencidas {
  background: #262626;
}
.Reportado {
  background: linear-gradient(to top, var(--yellow-default) 50%, #eb5757 50%);
}

@media (max-width: 836px) {
  .table-toggler {
    display: none;
  }

  .input-search-cirurgias {
    width: 100%;
  }

  .cirurgias-spacing {
    margin: 0 !important;
  }

  div .scroll-box {
    height: 100%;
  }

  .p-adjust {
    padding: 0 !important;
    padding-top: 2rem !important;
  }
}

