input.input-vendedores {
  border-radius: 16px !important;
}

@media (max-width: 832px) {
  .input-vendedores {
    padding: 0;
    margin-top: 18px;
    padding-bottom: 16px;
  }
  .input-form-login-create-account {
    /* font-size: 24px !important; */
  }
  .input-senha-input {
    min-width: calc(50vw - 48px);
  }
  .form-fornecedor-label-password {
    /* font-size: 20px !important; */
  }
  .form-fornecedor-label-confirm-password {
    /* font-size: 20px !important; */
    white-space: nowrap;
  }
  /* .form-fornecedor-input-password {
  }
  .form-fornecedor-input-confirm-password {
  } */
}

@media (max-width: 414px) {
  .input-vendedores {
    padding: 0;
    margin-top: 18px;
    padding-bottom: 16px;
  }
  .input-form-login-create-account {
    /* font-size: 24px !important; */
  }
  .input-senha-input {
    min-width: calc(50vw - 48px);
  }
  .form-fornecedor-label-password {
    /* font-size: 20px !important; */
  }
  .form-fornecedor-label-confirm-password {
    /* font-size: 20px !important; */
    white-space: nowrap;
  }
  /* .form-fornecedor-input-password {
  }
  .form-fornecedor-input-confirm-password {
  } */
}
@media not all and (min-resolution:.001dpcm) { 
  .input-login-eye {
    margin-top: 4px;
  }
}