@media (max-width: 832px) {
  .select-page-container {
    width: 100vw;
    height: 100%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80px;
  }
  .select-page-adjust-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0;
    padding: 0;
    margin-top: 40px;
  }
  .select-page-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 65px;
    color: var(--white);
    display: none;
  }
  
  .intro-text-message {
    color: var(--primary-blue-default);
    text-align: center;
    font-weight: 900;
    padding-inline: 1rem;
    top: calc(73%);
  }
}
/* @media (max-width: 414px) {
  .select-page-container {
    width: 100vw;
    padding-top: 120px !important;
    padding-bottom: 0;
  }
  .select-page-adjust-container {
    margin: 0;
    padding: 0;
    margin-top: 40px;
  }
  .select-page-title {
    font-family: 'Lato';
    margin-left: 20px;
    color: var(--white);
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
  }
} */