.button-bold-24 {
  font-weight: bold;
  font-size: 24px;
  color: white;
  background-color: #2460D7;
  border-radius: 20px; 
}

.button-bold-25 {
  font-weight: bold;
  font-size: 24px;
  color: #2460D7;
  background-color: white;
  border-radius: 20px; 
  border: 1px, solid, #2460D7
}

.button-bold-24:hover {
  transform: scale(1.1);
  transition: 0.1s;
}


.arrow-timeline {
  width: 10px;
  height: 30px;
  background-color: rgb(233, 239, 251);
}

.circle-timeline {
  width: 24px;
  height: 25px;
  border-radius: 100%;
  background-color: #2460D7;
}

.date-timeline {
  color: #2460D7;
}

.arc-out {
  border-radius: 100px 100px 0 0;
}

.arc-in {
  border-radius: 0 0 100px 100px;
}
