@media (max-width: 836px) {
    .relatorio-container input,
    .relatorio-container label {
      font-size: 14px !important;
      /* height: 34px; */
      
    }

    .relatorio-container input {
        min-width: 150px !important;
    }

    .relatorio-container input#data-inicial,
    .relatorio-container input#data-final {
        width: 50px;
    }
  }