.meuPerfilButtonClass {
    color: rgb(179, 179,179);
}

.fixed-width-sidebar {
    width: 295px;
    min-width: 295px;
    max-width: 295px;
  }
  .close-button {
    display: none;
  }

  .mobile-header-menu-list-container {
    display: none;

  }

  @media (max-width: 832px) {
    .fixed-width-sidebar {
      position: fixed;
      top: 0;
      left: -100%; /* Fora da tela inicialmente em telas pequenas */
      width: 100%;
      /* height: 100vh; */
      background-color: var(--primary-blue-default);
      /* box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2); */
      z-index: 5000;
      transition: left 0.3s ease-in-out;
      display: flex;
      /* flex-direction: row; */
      padding: 1rem;
      max-width: 100% !important;
      border: none;
      align-items: flex-start;
      padding-left: 1.6rem !important;
      padding-right: 1.6rem !important;
      overflow: scroll;
    }
    div .contentContainer {
        margin-left: 0 !important;
    }

    .show-mobile-sidebar {
      left: 0;
    }

    .sidebar-links-mobile {
      color:white !important;
    }

    .meuPerfilButtonClass {
      color: white !important;
  }

  .close-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* Espaço entre o ícone e o texto */
    padding: 0.5rem 1rem;
    color: white;
    font-size: 1rem;
    font-weight: 400; /* Peso normal para o texto "Fechar" */
    border: none;
    border-radius: 4px;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
    
    .close-icon {
      font-weight: bold;
    }
    
    /* Hover */
    .close-button:hover {
      cursor: pointer;
    }

    .logo-remover-mobile {
      display: none;
    }
    
    .mobile-header.logo {

    }
    .mobile-header-menu-list-container {
      display: flex;
      align-items: center;  /* Alinha os itens verticalmente no centro */
      gap: 1rem;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .mobile-header-user-img {
      width: 65px !important; /* Ajuste o tamanho da imagem conforme necessário */
      height: 65px !important; /* Certifique-se de que altura e largura sejam iguais */
      border-radius: 50%; /* Torna a imagem circular */
      object-fit: fill; /* Garante que a imagem se ajuste ao espaço sem distorção */
      border: 2px solid #ccc; /* (Opcional) Adiciona uma borda ao redor da imagem */
      /* padding-top: 10px */
    }
 
  }
  

  /* Media Query para telas maiores (acima de 832px) */
  @media (min-width: 833px) {
    .fixed-width-sidebar {
      /* display: block; Exibe o sidebar em telas maiores: */
    }

  }

  /* Estilo padrão para o container de grid */
.card-status {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 colunas iguais */
    gap: 20px; /* Espaçamento entre os itens */
    padding-top: 1.25rem; /* pt-5 corresponde a 1.25rem */
  }
  
  /* Para telas pequenas (<= 832px) */
  @media (max-width: 832px) {
    .card-status {
      grid-template-columns: 1fr; /* Apenas uma coluna */
    }

    .title-prox-cirugias {
        text-align: center;
    }

    .py-16 {
        /* margin-top: 40px; */
    }

    .z-10 {
        height: 80px !important;
    }
}


  