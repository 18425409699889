.card-autorizacao {
  /* width: 291.07px; */
  /* height: calc(100% - 0rem); */
  /* background: var(--white); */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /*border: 1px solid black;*/
  padding: 5px;
  transition: 0.2s;
}
.card-autorizacao:hover {
  transform: scale(0.99);
  cursor: pointer;
  transition: 0.2s;
}
div h5.name-paciente {
  color: var(--neutral-100) !important;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
}
.state-cirurgia {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
.Autorizada {
  background: #eb5757;
}
.Pendentes {
  background: var(--yellow-default);
}

.Solicitada {
  background: rgb(255, 136, 0)
}

@media (max-width: 768px) {
  div.card-autorizacao {
    /* display: none; */
  }
  h1.title-prox-cirugias {
    /* display: none; */
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  div.card-autorizacao {
    /* display: none; */
  }
  h1.title-prox-cirugias {
    /* display: none; */
  }
}
@media (max-width: 832px) {
  .card-autorizacao {
    box-shadow: none; 
    background: var(--white);
    /* box-shadow: 2px 2px 20px rgba(63, 61, 77, 0.1); */
    /* border-radius: 8px; */
  }
  
  /* div.card-autorizacao {
    display: unset;
  }
  h1.title-prox-cirugias {
    display: unset;
  }
  div.card-autorizacao {
    display: unset;
  }
  h1.title-prox-cirugias {
    display: unset;
  } */
  .div-card-autorizacao {
    margin-top: 30px;
  }
}

/* @media (max-width: 414px) {
  .card-autorizacao {
    box-shadow: none; 
    background: var(--white);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  div.card-autorizacao {
    display: unset;
  }
  h1.title-prox-cirugias {
    display: unset;
  }
  div.card-autorizacao {
    display: unset;
  }
  h1.title-prox-cirugias {
    display: unset;
  }
} */