div.btn-postFornecedor {
  transition: 0.2s ease-in;
}
div.btn-postFornecedor:hover {
  transform: scale(1.01) !important;
  transition: 0.2s ease-in;
}

div textarea.report-text-area::-webkit-scrollbar {
  opacity: 0;
  width: 0px;
  animation: animate 0.2s ease-in-out;
}

div textarea.report-text-area:hover::-webkit-scrollbar {
  opacity: 1;
  width: 3px;
  animation: animate 0.2s ease-in-out;
}

div textarea.report-text-area::-webkit-scrollbar-track {
  background-color: #fff;
  width: 5px;
}

div textarea.report-text-area::-webkit-scrollbar-thumb {
  background-color: var(--neutral-500);
  width: 3px;
  border-radius: 52px;
}

.Toastify__toast {
  /* background-color: #22bb33 !important; */
  color: white !important;
  border-left: 10px;
  border-color: rgba(0, 0, 0, 0.3);
  border-style: solid !important;
  border-radius: 5px !important;
}
.Toastify__close-button {
  color: white !important;
  display: none !important;
}

.Toastify__toast-body {
  padding: 0 !important;
  font-weight: 500;
  font-size: 0.9rem;
}

.toastSuccessColor {
  background-color: var(--green-default) !important;
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
