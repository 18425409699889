.bg-login {
  background-image: url(../../assets/bg-intro.png);
}

.img-login {
  background-image: url(../../assets/img-intro.png);
}

/* .form-body {
  width: 457px;
  height: 100%;
  background: var(--white);
  box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  position: relative;
  padding-left: 10px;
} */

.btn-dropdown:hover .dropdown-content {
  display: block;
}

.input-border-radius {
  border-radius: 16px !important;
}

.form-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 38px;
  color: var(--primary-blue-default);
  cursor: default;
}

.form-subtitle.patient-solicitation {
  padding: 16px !important;
}

.anx-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  color: var(--primary-blue-default);
}

.form-subtitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: var(--neutral-300);
}
div.form-input-erro {
  outline: none;
  border: 2px solid red;
  transition: 0.1s;
  box-sizing: border-box;
  border-radius: 16px;
  background: rgba(255, 192, 192, 0.15);
  width: 392.79px;
  height: 43.79px;
}
input.erro-input {
  outline: none;
  border: 2px solid red;
  transform: scale(1.01);
  transition: 0.1s;
  background: rgba(255, 192, 192, 0.15);
  box-sizing: border-box;
  border-radius: 16px;
  width: 392.79px;
  height: 43.79px;
}
div.erro-input {
  outline: none;
  border: 2px solid red;
  transform: scale(1.01);
  transition: 0.1s;
  background: rgba(255, 192, 192, 0.15);
  box-sizing: border-box;
  border-radius: 16px;
  width: 392.79px;
  height: 43.79px;
}

input.erro-input-sm {
  outline: none;
  border: 2px solid red;
  transform: scale(1.01);
  transition: 0.1s;
  background: rgba(255, 192, 192, 0.15);
  box-sizing: border-box;
  border-radius: 16px;
  width: 183.77px;
  height: 43.79px;
}
input.form-input-disabled {
  background: rgb(249, 249, 249);
  border: 0.5px solid #b5b5b5;
  box-sizing: border-box;
  border-radius: 16px;
  width: 392.79px;
  height: 43.79px;
}

input.form-input-disabled-sm {
  background: rgb(249, 249, 249);
  border: 0.5px solid #b5b5b5;
  box-sizing: border-box;
  border-radius: 16px;
  width: 183.77px;
  height: 43.79px;
}

.lb-input {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-100);
}

.form-input-login {
  background: var(--white);
  border: 0.5px solid var(--neutral-400);
  box-sizing: border-box;
  border-radius: 16px;
  /* width: 300px; */
  height: 43.79px;
}
.form-input-sm {
  /* width: 183.77px; */
  height: 43.79px;
  background: var(--white);
  border: 2px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 16px;
}

.btn-input {
  width: 80px;
  height: 80px;
  background: var(--primary-blue-default);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  transition: 0.2s;
}

.btn-input:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

::-webkit-file-upload-button {
  background: var(--primary-blue-default);
  color: var(--white);
  outline: none;
  border: none;
  border-radius: 12px;
  margin-top: 8px;
  margin-right: 50px;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: bold;
}

.btn-calendar {
  position: absolute;
  margin-top: 0px;
  right: 45px;
  color: var(--primary-blue-default);
}

.link-cadastro {
  color: var(--primary-blue-default);
  font-weight: bold;
}

.p-link-cadastro {
  font-size: 18px;
}

.logo-style {
  font-weight: 500;
}
span.erro-span {
  margin-top: -15px !important;
  position: absolute;
  right: 15px;
}
span.erro-span-cp {
  margin-top: -15px !important;
  position: absolute;
}
span.erro-span-2 {
  margin-top: -10px !important;
  position: absolute;
  right: 25px;
}
span.erro-span-sm {
  margin-top: 0px !important;
  position: absolute;
  right: 15px;
  z-index: 0 !important;
}
label {
  font-weight: bold;
  color: var(--neutral-400);
}

/* input[type="radio"] + label span {
  display: none;
  background-color: var(--neutral-400);
  color: var(--neutral-400) !important;
}

input[type="radio"]:checked + label span {
  background-color: white !important;
  box-shadow: 0px 0px 0px 4px var(--primary-blue-default) inset !important;
  transform: scale(1.05) !important;
  transition: 0.2s !important;
}

input[type="radio"]:checked + label {
  color: var(--primary-blue-default) !important;
} */

/* input[type="radio"]:checked + label span {
  background-color: white !important;
  box-shadow: 0px 0px 0px 4px var(--primary-blue-default) inset !important;
  transform: scale(1.05) !important;
  transition: 0.2s !important;
} */

textarea:focus,
input:focus {
  outline: none;
  border: 2px solid var(--primary-blue-default);
  transform: scale(1.01);
  transition: 0.1s;
}

.DayPicker-wrapper,
.DayPicker-Day {
  outline: none !important;
  transition: 0.1s ease-in !important;
  font-size: 0.95rem !important;
  cursor: pointer !important;
}
.DayPicker {
  width: 350px !important;
  height: 100% !important;
}
.DayPicker-Day:focus-within {
  border-bottom: 3px solid var(--green-default) !important;
  color: var(--green-default) !important;
  font-weight: bold !important;
  transition: 0.1s ease-in !important;
}
span.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("../../../src/assets/prev.png") !important;
}

span.DayPicker-NavButton--next {
  background-image: url("../../../src/assets/next.png") !important;
}
span.DayPicker-NavButton--prev,
span.DayPicker-NavButton--next {
  outline: none !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
div.DayPicker-WeekdaysRow {
  font-weight: bold !important;
  color: #b5b5b5 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.toggleContato {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

/* .toggleContato > span.toggleText {
  color: var(--primary-blue-default);
} */

.toggleContato > input.toggleContato {
  height: 1.5rem;
  width: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid #cbd5e0;
  border-radius: 100%;
  outline: none;
  transition-duration: 0.3s;
  background-color: #cbd5e0;
  cursor: pointer;
  transition: 0.2s;
}

.toggleContato > input.toggleContato:checked {
  border: 5px solid #0b4fd6;
  background-color: var(--white);
  transition: 0.2s;
}

/* .toggleContato > input.toggleContato:checked + span.toggleText::before {
  display: block;
  text-align: center;
  color: var(--primary-blue-default);
  position: absolute;
  left: 0.7rem;
  top: 0.2rem;
} */

/* @media (max-width: 768px) {
  .bg-login {
    opacity: 0;
  }
  .form-body {
    border-radius: 32px 32px 0px 0px !important;
    margin-top: -30px;
    height: 100vh;
  }

  input[type="radio"]:checked + label span {
    background-color: white !important;
    box-shadow: 0px 0px 0px 4px var(--primary-blue-default) inset !important;
    transform: scale(1.05) !important;
    transition: 0.2s !important;
  }
} */

/* @media (min-width: 800px) and (max-width: 850px) {
  .bg-login {
    opacity: 0;
  }
  .form-body {
    box-shadow: none;
  }
  input[type="radio"]:checked + label span {
    background-color: white !important;
    box-shadow: 0px 0px 0px 4px var(--primary-blue-default) inset !important;
    transform: scale(1.05) !important;
    transition: 0.2s !important;
  }
} */
