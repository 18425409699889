@media (max-width: 832px) {
  /* .form-title {
    background: var(--primary-blue-default);
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    color: var(--white);
    padding-top: 50px;
    margin-top: -328px;
    padding-left: 18px;
  } */
  .form-subtitle {
    background: var(--primary-blue-default);
    color: var(--white);
  }
  .fornecedor-create-account-form {
    background: var(--primary-blue-default);
    width: 100vw;
  }
  /* .input-senha-container {
  } */
  .floating-back-link {
    position: absolute;
    top: 20px;
    width: min-content;
    height: 20px;
    left: 36px;
    color: var(--neutral-200);
    font-weight: 600;
    font-size: 1rem;
  }
  .arrow-bar-top {
    position: absolute;
    background: var(--neutral-200);
    width: 13px;
    left: -20px;
    transform: rotate(-45deg);
    top: 5px;
    height: 4px;
  }
  .arrow-bar-bottom {
    position: absolute;
    top: 12px;
    background: var(--neutral-200);
    width: 13px;
    left: -20px;
    height: 4px;
    transform: rotate(45deg);
  }
  .bg-fornecedor-mobile {
    background-color: var(--primary-blue-default);
  }
}
@media (max-width: 414px) {
  .form-title {
    background: var(--primary-blue-default);
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    color: var(--white);
    padding-top: 50px;
    margin-top: -112px;
    padding-left: 18px;
  }
  .form-subtitle {
    background: var(--primary-blue-default);
    color: var(--white);
  }
  .fornecedor-create-account-form {
    background: var(--primary-blue-default);
  }
  /* .input-senha-container {
  } */
  .floating-back-link {
    position: absolute;
    top: 20px;
    width: min-content;
    height: 20px;
    left: 36px;
    color: var(--neutral-200);
    font-weight: 600;
    font-size: 1rem;
  }
  .arrow-bar-top {
    position: absolute;
    background: var(--neutral-200);
    width: 13px;
    left: -20px;
    transform: rotate(-45deg);
    top: 5px;
    height: 4px;
  }
  .arrow-bar-bottom {
    position: absolute;
    top: 12px;
    background: var(--neutral-200);
    width: 13px;
    left: -20px;
    height: 4px;
    transform: rotate(45deg);
  }
}