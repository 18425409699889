:root {
  --primary-blue-default: #2460D7;
  --primary-blue-default-20: rgba(36, 96, 215, .2);
  --primary-blue-dark: #0D48BD;
  --primary-blue-light: #54C1FB;

  --red-default: #EB5757;
  --red-default-20: rgba(235, 87, 87, .2);
  --red-dark: #CC4B4C;
  --red-light: #FFDBDB;

  --green-default: #27AE60;
  --green-default-20: rgba(39, 174, 96, 0.2);
  --green-dark: #108742;
  --green-light: #E0FFED;

  --yellow-default: #F2C94C;
  --yellow-dark: #CCA42B;
  --yellow-light: #FFF3CF;

  --secondary-yellow-default: #D6BA24;
  --secondary-yellow-dark: #B0960C;
  --secondary-yellow-light: #F0D64D;
  --secondary-yellow-light-20: rgba(240, 214, 77, 0.2);

  --white: #FFFFFF;
  --black: #000000;
  --black-40: rgba(0, 0, 0, 0.4);
  --neutral-100: #272848;
  --neutral-200: #3F3D4D;
  --neutral-300: #4F4F4F;
  --neutral-400: #828282;
  --neutral-500: #B3B3B3;
  --neutral-600: #E0E0E0;
  --neutral-700: #ECECEC;
  --neutral-800: #F5F5F5;
}
* {
  -webkit-tap-highlight-color: transparent;
  
  @keyframes ShowWrapper {
    from {
      display: none;
    } to {
      display: unset;
      z-index: 3;
    }
  }


  @keyframes HideWrapper {
    from {
      z-index: 1;
    } to {
      z-index: -1;
    }
  }

  @keyframes ShowBlur {
    from {
      opacity: 0;
    } to {
      opacity: 1;
    }
  }

  @keyframes HideBlur {
    from {
      opacity: 1;
    } to {
      opacity: 0;
    }
  }
}