.popup-content {
  margin: auto;
  background: transparent;
  /* width: 50% !important; */
}
@media (max-width: 768px) {
  .popup-content {
    /* width: 100% !important; */
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .popup-content {
    /* width: 100% !important; */
  }
}
