/* Scroll Bar */
select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
select__menu-list::-webkit-scrollbar-thumb {
  background: #888;
}
select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media(max-width: 832px) {
  .mobile-editar-agendadas-wrapper {
    min-width: unset !important;
    width: unset !important;
  }
  .input-vendedores.mobile-edit-agendadas {
    padding: 0 8px !important;
  }
}