.notifications-content {
  transition: 0.2s;
  animation: animate 0.2s ease-in-out;
  width: 330px;
  margin-left: -145px;
  margin-top: 5px;
}
.active-dropdown {
  height: 100%;
  opacity: 1;
}

.inactive-dropdown {
  height: 0px;
  opacity: 0;
}
div.scroll-box-nf {
  height: 255px;
  background-color: transparent !important;
}

div.scroll-box-nf::-webkit-scrollbar {
  width: 0px;
}

div.scroll-box-nf:hover::-webkit-scrollbar {
  opacity: 1;
  width: 3px;
}

div.scroll-box-nf::-webkit-scrollbar-track {
  background-color: transparent;
  width: 5px;
}

div.scroll-box-nf::-webkit-scrollbar-thumb {
  background-color: white;
  width: 3px;
  border-radius: 52px;
}
div span.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid var(--primary-blue-default);
}

.Agendadas {
  background: var(--green-default);
}
.Autorizada {
  background: #eb5757;
}
.Pendentes {
  background: var(--yellow-default);
}
.Concluida {
  background: var(--primary-blue-default);
}
.Desmarcada {
  background: #b6b6b6;
}
