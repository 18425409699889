.modal-assinatura {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 550px;
  padding: 15px 20px 20px 20px ;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.custom-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #EBEBEB;
  border-radius: 8px;
  padding: 12px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  height: 50px;
}

.text-content-color {
  color: #4F4F4F;
}

.custom-button:hover {
  background-color: #ffffff;
  transform: scale(1.05);
}

.logo {
  height: 2.2rem;
  /* margin-right: 5px; */
  vertical-align: middle;
}


.arrow {
  font-size:  18px;
}

.footer-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin: 0;
  white-space: nowrap;
}

@media (max-width: 736px) {
  .modal-assinatura{
    width: 90%;
    font-size: 16px !important;

  }
  
  .footer-text {
   font-size: 14px;
  }

  .assinatura-tittle {
    font-size: 18px
  }

  .input-field {
    font-size: 18px !important;
    width: 100% !important;
    
  }
  
  .input-label {
    font-size: 18px !important;
  }

  .input-container {
    padding-inline: 0 !important;
  }

  .button-assinatura-prosseguir {
    width: 100% !important;
    font-size: 18px !important;
  }

  .timer-label {
    font-size: 24px !important;
  }
  
  .timer-display {
    font-size: 70px !important;
  }
}

