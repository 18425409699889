.modal-new {
        scale: calc(0.9);
        width: 100%;
        /* height: 100vh; */
        /* max-width: 100%; */
        /* max-height: 100%; */
        position: fixed;
        /* border-radius: unset !important; */
        /* top: 0; */
        left: 0;
        padding: 2rem;
        overflow-y: auto;
        border-radius: 25px;        
}