.trash-button:hover {
  transform: scale(1.1);
}

@media (max-width: 836px){


.secretario-img {
  width: 2.5rem;
  height: 2.5rem;
}

.secretario-text {
  font-size: 14px;
}

.letter-icon {
  width: 1rem;
  height: 1rem;
}

}