.SalvarModeloButton :disabled {
  background-color: grey;
  cursor: not-allowed;
}

@media (min-width: 832px) {
  .modelo-input {
    width: 320px;
  }

  .modelo-button {
    width: 90px;
  }
}