input.input-vendedores {
  border-radius: 16px !important;
  position: relative !important;
}
.input-vendedores {
  position: relative !important;
}
input.input-vendedores.error {
  border: 2px solid red;
  background: rgba(255, 192, 192, 0.15);
}
.label.file-btn-foto {
  transition: 0.2s;
}
div.btn-save-vendedor {
  transition: 0.2s ease-out;
}
div.btn-save-vendedor:hover {
  transform: scale(1.01);
  transition: 0.2s ease-in;
}
label.file-btn-foto:hover {
  transform: rotate(5deg);
  transition: 0.2s ease-in;
}
img.img-profile-modal {
  width: 150px;
  height: 150px;
}

@media (max-width: 768px) {
  .modal {
    /* scale: calc(0.9); */
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    border-radius: unset !important;
    top: 0;
    left: 0;
    padding: 2rem;
    overflow-y: auto;
    /* border-radius: 0 !important; */
  }
  .close-btn {
    right: -34px;
    top: -34px;
  }

  .input-vendedores {
    width: 100%;
    margin-top: 0;
    padding-bottom: 0;
    padding: 3px
  }

  .popup-overlay {
    display: unset !important;
  }

  @media (max-width: 420px) {

  }

}
