.card-status {
  /* width: 100%; */
  height: calc(100% - 2.75rem);
  position: relative;
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  /*border: 1px solid black;*/
}

.Agendadas {
  background: var(--green-default);
}
.Autorizada {
  background: #eb5757;
}
.Pendentes {
  background: var(--yellow-default);
}

@media (max-width: 768px) {
  .card-status {
    /* display: none; */
  }
  .title-prox-cirugias {
    /* display: none; */
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  .card-status {
    /* display: none; */
  }
  .title-prox-cirugias {
    /* display: none; */
  }
}
