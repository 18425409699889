.div-card-calendario {
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
.div-teste {
  height: 125.69px;
  background: var(--green-light);
}
.search-icon-c {
  color: #b5b5b5;
  font-weight: bold;
}
div input.search-bar {
  border-bottom: 0.5px;
  border-style: solid;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  color: var(--primary-blue-default);
  background-color: transparent;
}
div input.search-bar:focus {
  border-style: solid;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  transform: scale(1);
}
div input.search-bar-crud {
  border-bottom: 2px;
  border-color: #b2b2b2;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  transform: scale(1);
}
div input.search-bar-crud:focus {
  border-bottom: 2px;
  border-color: var(--primary-blue-default);
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  transform: scale(1);
}
div.scrollbox-calendario {
  max-height: 296px;
  background-color: transparent;
}

div.scrollbox-calendario::-webkit-scrollbar {
  background-color: red;
  width: 0px;
}

div.scrollbox-calendario:hover::-webkit-scrollbar {
  opacity: 1;
  width: 3px;
}

div.scrollbox-calendario::-webkit-scrollbar-track {
  background-color: #fff;
  width: 5px;
}

div.scrollbox-calendario::-webkit-scrollbar-thumb {
  background-color: var(--neutral-500);
  width: 3px;
  border-radius: 52px;
}
.search-calendario {
  height: 48px;
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 66px;
}
.border-calendario {
  border-left: 18px solid;
}
.border-Agendado {
  border-color: var(--green-default);
}
.hora-cirurgia,
.data-calendario-style {
  color: var(--primary-blue-default);
}
.ver-tudo {
  color: var(--primary-blue-default);
  cursor: pointer;
}
.DayPicker-wrapper,
.DayPicker-Day {
  outline: none !important;
  transition: 0.1s ease-in;
  font-size: 0.95rem;
  cursor: pointer !important;
}
.DayPicker {
  width: 100%;
  height: 100%;
}

.DayPicker-Day--selected {
  border-bottom: 3px solid var(--green-default);
  background-color: transparent !important;
  border-radius: 0px !important;
  color: var(--green-default) !important;
  font-weight: bold;
  transition: 0.1s ease-in;
}
.DayPicker-Day--today {
  color: var(--primary-blue-default);
  font-weight: 700 !important;
}
.DayPicker-Caption {
  font-weight: bold !important;
}
.DayPicker-Day:focus {
  border-bottom: 3px solid var(--primary-blue-default) !important;
  color: var(--primary-blue-default) !important;
  font-weight: bold;
  transition: 0.1s ease-in;
}
span.DayPicker-NavButton--prev,
span.DayPicker-NavButton--next {
  outline: none !important;
  width: 1.5rem;
  height: 1.5rem;
}
div.DayPicker-WeekdaysRow {
  font-weight: bold;
  color: #b5b5b5;
}
button.btn-next-cirurgia:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in;
}
button.btn-prev-cirurgia:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in;
}
@media (max-width: 768px) {
  .calendario-page {
    /* display: none; */
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  .calendario-page {
    /* display: none; */
  }
}

@media (max-width: 836px) {
  .mobile-calendario-container {
    display: flex !important;
    flex-direction: column !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .div-card-calendario {
    min-width: 340px;  
  }

  .rdp-months {
    justify-content: center;
    padding: 1rem
  }

  .calendario-page {
    padding: 0 !important;
  }

  .search-calendario {
    display: none !important;
  }

  .calendario-container-child {
    padding-top: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
  }

  .calendario-cirurgias {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
