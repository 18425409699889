div span.delete-file-btn:hover {
  transform: scale(1.3);
  transition: 0.3s;
}

div span.delete-file-btn {
  margin-top: 5px;
}

.radius {
  border-radius: 16px;
}

/* .DayPicker-wrapper,
.DayPicker-Day {
  outline: none !important;
  transition: 0.1s ease-in !important;
  font-size: 0.95rem !important;
  cursor: pointer !important;
}
.DayPicker {
  width: 350px !important;
  height: 100% !important;
}
.DayPicker-Day:focus-within {
  border-bottom: 3px solid var(--green-default) !important;
  color: var(--green-default) !important;
  font-weight: bold !important;
  transition: 0.1s ease-in !important;
}
span.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("../../assets/prev.png") !important;
}
span.DayPicker-NavButton--next {
  background-image: url("../../assets/next.png") !important;
}
span.DayPicker-NavButton--prev,
span.DayPicker-NavButton--next {
  outline: none !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
div.DayPicker-WeekdaysRow {
  font-weight: bold !important;
  color: #b5b5b5 !important;
}
/* 
*/
input.radio-input:checked + label.radio-label span.radio-span {
  background-color: white !important;
  box-shadow: 0px 0px 0px 4px var(--primary-blue-default) inset !important;
  transform: scale(1.05) !important;
  transition: 0.2s !important;
}
.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
  width: 350px;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}
.typeahead-list {
  position: absolute;
  z-index: 1000;
  /* width: 100%; */
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}