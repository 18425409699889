.modal-secretariado {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* min-width: 341px; */
  /* min-height: 174px; */
  padding: 15px 20px 20px 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  z-index: 1000;

}

.modal-secretariado {
  display: inline;
}

.secretariado-buttons:hover {
  transform: scale(1.1);
}

@media (max-width: 836px) {
  .secretariado-buttons {
    height: 30px !important;
    width: 100px !important;
    font-size: 14px;
  }
}