div input.input-email:focus-within {
  outline: none;
  border-top: none;
  border-bottom: 2px solid var(--primary-blue-default);
  border-left: none;
  border-right: none;
  transition: 0.1s;
}
input.input-cirurgiao {
  border-radius: 16px !important;
  background-color: #f9f9f9;
  border-color: #dcdcdc;
  user-select: none !important;
}

div p.input-email:focus-within {
  outline: none;
  border-top: none;
  border-bottom: 2px solid var(--primary-blue-default);
  border-left: none;
  border-right: none;
  transition: 0.1s;
}
p.input-cirurgiao {
  border-radius: 16px !important;
  background-color: #f9f9f9;
  border-color: #dcdcdc;
  user-select: none !important;
}
