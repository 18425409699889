nav {
  box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.15);
  height: 110px;
}

/* .btn-dropdown:hover .dropdown-content {
  display: block;
  transform: scale(1.1);
  animation: animate 0.2s ease-in-out;
} */
.notification-n {
  padding: 0px 6px 0px 6px;
}
div.css-4ljt47-MenuList::-webkit-scrollbar {
  background-color: transparent;
  width: 3px;
}

div.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 16px;
  opacity: 0;
}
button {
  transition: 0.1s;
  /* border-radius: 100%; */
}
div.perfil-style {
  border: 2px solid transparent !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 20ch; */
  overflow: hidden;
  margin-top: 5px;
}
button:focus {
  outline: none;
  transition: 0.1s;
  /* border-radius: 100%; */
}
.link-nav {
  color: var(--neutral-500);
  font-weight: 600;
  transition: 0.5s;
}
.link-nav:focus,
.active {
  color: var(--primary-blue-default);
  transition: 0.5s;
  font-weight: 600;
  transform: scale(1.05);
}

.bg-blue {
  background-color: var(--primary-blue-default);
}
.text-blue {
  color: var(--primary-blue-default);
}

/* Mobile */

.mobile-header-container {
  display: flex;
  width: 100%;
  /* padding: 28px 23px 20px; */
  /* gap: 64px; */
  background: var(--white);
  box-shadow: 0px 2px 15px 0px #3F3D4D1A;
  border-radius: 0px 0px 8px 8px;
  height: min-content;
  height: 60px;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
  align-items: center;
}
.hamburger-container {
  /* padding-left: 10px; */
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 32px;
  /* margin-top: 12px; */
  align-self: center;
  cursor: pointer;
}
.hamburger-bar {
  width: 34px;
  height: 3px;
  border-radius: 2px;
  background: var(--primary-blue-default);
}
.mobile-header-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: min-content;
}
.mobile-header-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: var(--neutral-200);
}
.mobile-header-info {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.408px;
  color: var(--primary-blue-default);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 230px;
}

/* Mobile Menu */
.mobile-header-menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  min-width: 300px;
  background: var(--primary-blue-default);
  padding: 30px 0 48px 20px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}
.mobile-header-menu-top-container {
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: min-content;
  padding: 4px 0 0 4px;
}
.cross-container {
  position: relative;
  width: 20px;
  height: 20px;
}
.cross-bar-left {
  width: 24px;
  height: 3px;
  background: var(--white);
  border-radius: 2px;
  transform: rotate(45deg);
  position: absolute;
  top: 8px;
  left: -2px;
}
.cross-bar-right {
  width: 24px;
  height: 3px;
  background: var(--white);
  border-radius: 2px;
  transform: rotate(-45deg);
  position: absolute;
  top: 8px;
  left: -2px;
}
.mobile-header-menu-close-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--white);
}
.mobile-header-user-img {
  max-width: 100px;
}
.mobile-header-menu-center-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.mobile-header-menu-list-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 36px;
}
.mobile-header-menu-option {
  display: flex;
  gap: 22px;
  align-items: center;
}
.mobile-header-menu-option-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
}
.mobile-header-menu-option-user-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
}
.mobile-header-menu-notification-badge {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--secondary-yellow-light);
  position: absolute;
  left: 10px;
  top: 0;
}
.mobile-header-menu-end-logo {
  margin: 0 auto;
  margin-bottom: 0px;
}

nav .navbarClassName {
  left: 276px;
}

/* @media (max-width: 768px) {
  nav.nav-desktop {
    display: none;
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  nav.nav-desktop {
    display: none;
  }
} */

.z-header {
  z-index: 997;
}

.z-sidebar {
  z-index: 998;
}
