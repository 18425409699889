.accordion-pos-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 8px;
}
.accordion-pos-wrapper.spacing {
  padding-bottom: 16px;
}
.accordion-pos-title-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
}
.accordion-pos-title-container {
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
}
.accordion-pos-check-icon-container {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--primary-blue-default);
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-color: rgba(203, 213, 224, .5);
}
.accordion-pos-check-icon {
  color: var(--white);
}
.accordion-pos-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue-default);
}
.accordion-pos-arrow {
  transition: transform ease .3s;
  min-width: 16px;
}
.accordion-pos-arrow.flip {
  transform: rotate(180deg);
}
.accordion-pos-content-container {
  opacity: 0;
  height: 0;
  z-index: -1;
}
.accordion-pos-content-container.show {
  transition: opacity ease .3s;
  opacity: 1;
  height: unset;
  z-index: unset;
}
.accordion-pos-centerbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  padding: 0 28px;
}
.accordion-pos-centerbox-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--neutral-100);
}
.accordion-pos-textarea {
  padding: 7px 10px;
  border: 2px solid var(--black);
  border-radius: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
  height: 100px;
  resize: none;
}
.accordion-pos-textarea:disabled {
  background-color: unset;
  cursor: default;
}
.accordion-pos-attachments-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--neutral-100);
  padding: 0 28px;
  margin-top: 40px;
}
.accordion-pos-attachments-anchor-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 0 28px;
}
.accordion-pos-attachments-anchor {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #54C1FB;
}
.accordion-pos-cross-container {
  position: relative;
  margin-top: 2px;
  width: 10px;
  height: 10px;
}
.accordion-pos-cross-bar-left {
  position: absolute;
  width: 10px;
  height: 1px;
  border-radius: 2px;
  background-color: #EB5757;
  left: 0;
  transform: rotate(45deg);
  bottom: 4.5px;
  cursor: pointer;
}
.accordion-pos-cross-bar-right {
  position: absolute;
  width: 10px;
  height: 1px;
  transform: rotate(-45deg);
  bottom: 4.5px;
  border-radius: 2px;
  background-color: #EB5757;
}

@media (max-width: 832px) {
  .accordion-pos-title {
    margin: auto;
  }
}