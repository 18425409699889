.card-admin {
  /* height: 111.68px; */
  height: 150px;
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  transition: 0.2s;
}

.card-admin:hover {
  transform: scale(1.01);
  transition: 0.2s;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
}
