div.scroll-box-timeline {
  height: 500px;
  background-color: transparent !important;
}

div.scroll-box-timeline::-webkit-scrollbar {
  background-color: red;
  width: 0px;
}

div.scroll-box-timeline:hover::-webkit-scrollbar {
  opacity: 1;
  width: 3px;
}

div.scroll-box-timeline::-webkit-scrollbar-track {
  background-color: #fff;
  width: 5px;
}

div.scroll-box-timeline::-webkit-scrollbar-thumb {
  background-color: var(--neutral-500);
  width: 3px;
  border-radius: 52px;
}
.timeline-card-content {
  background: transparent !important;
  line-height: 1.3rem !important;
  transform: scale(1) !important;
  width: 50%;
  text-shadow: none !important;
}
.timeline-card-content:focus {
  transform: scale(1) !important;
}
.css-1r6hgck-Circle.active {
  background-color: #3460d7;
  transition: 0.5s !important;
  border-radius: 100%;
  border: 5px solid white;
  transform: scale(1.5) !important;
}
.css-1r6hgck-Circle.in-active {
  background-color: #3460d7;
  transition: 0.5s !important;
  border-radius: 100%;
  border: 5px solid white;
  transform: scale(1.5) !important;
}
.timeline-vertical-circle {
  transform: scale(1) !important;
  transition: 0s !important;
}
.css-1y4fq2m-VerticalCircleWrapper::after {
  background-color: #dfe7ff !important;
}
div.report-btn:hover {
  transition: 0.2s ease-in;
}
