.title-prox-cirugias {
  color: var(--neutral-300);
  /* font-size: 18px; */
}
.card {
  /* width: 245.38px; */
  height: calc(100% - 0rem);
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  /*border: 1px solid black;*/
  padding: 15px;
  transition: 0.2s;
  min-width: 276px;
}
.card:hover {
  transform: scale(1.02);
  cursor: default;
  transition: 0.2s;
}
span.state-cirurgia {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
.Agendadas {
  background: var(--green-default);
}
.card-cirurgias-title {
  font-style: normal;
  font-weight: 900;
  /* font-size: 23px; */
  color: var(--primary-blue-default);
}
.card-cirurgias-subtitle {
  font-style: normal;
  font-weight: bold;
  /* font-size: 18px; */
  line-height: 22px;
  color: var(--neutral-100);
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
}
.card-cirurgias-small {
  /* font-size: 12px; */
  line-height: 14px;
  color: var(--neutral-400);
}
@media (max-width: 768px) {
  .card {
    /* display: none; */
  }
  .title-prox-cirugias {
    padding-top: 0 !important
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  .card {
    /* display: none; */
  }
  .title-prox-cirugias {
    /* display: none; */
  }
}

@media (max-width: 414px) {
  .card {
    box-shadow: 0px 2px 15px rgba(63, 61, 77, 0.1);
    border-radius: 8px;
  }
}

@media (min-width: 836px) {
  .height-filler {
    height: 132px
  }
}

