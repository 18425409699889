.img-title {
  font-family: Lato;
  font-style: normal;
  /* font-weight: 900; */
  /* font-size: 1.9rem; */
  /* line-height: 38px; */
  color: var(--primary-blue-default);
  width: 480px;
  text-align: center;
  cursor: default;
}
.img-subtitle {
  font-family: Lato;
  font-style: normal;
  /* font-weight: 600; */
  /* font-size: 0.9rem; */
  color: var(--neutral-300);
  width: 500px;
  cursor: default;
}
