.active-dropdown {
  height: 100%;
  opacity: 1;
  /* display: block; */
}

.inactive-dropdown {
  height: 0px;
  opacity: 0;
  /* display: none; */
}
.dropdown-content {
  transition: 0.2s;
  animation: animate 0.2s ease-in-out;
  width: 200px;
  margin-left: -60px;
  margin-top: 5px;
}
div span.arrow-up-2 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--primary-blue-default);
}
