/* .popup-content {
  margin: auto;
  background: transparent;
  width: 40% !important;
} */
.modal {
  animation: animate 0.2s ease-in-out;
  border-radius: 32px;
}
.close-btn {
  right: -10px;
  top: -10px;
  position: absolute;
  transition: 0.2s;
}
.close-btn:hover {
  transform: scale(0.95);
  transition: 0.5s;
}
.popup-root:focus {
  border: none !important;
}

div button.btn-new-vendedor:focus {
  border-radius: 32px;
}

div button.btn-new-vendedor2:focus {
  border-radius: 0px;
}


div button.btn-save-vendedor {
  transition: 0.2s;
}

div button.btn-save-vendedor:focus {
  border-radius: 32px;
}

div button.btn-save-vendedor:hover {
  transform: scale(1.01);
  transition: 0.2s;
}
