/* .accordion-section {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
} */
.accordion-content {
  height: 0px;
  display: none;
}
.accordion-title {
  white-space: nowrap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue-default);
}
.active-accordion {
  height: 100%;
  display: block;
}
.accordion-icon {
  transform: rotate(0deg);
  transition: 0.3s;
}
.accordion-icon-rotate {
  transform: rotate(180deg);
  transition: 0.3s;
  color: var(--primary-blue-default);
}
textarea.textarea-modal {
  border-radius: 10px;
  padding: 5px;
}
input.input-modal:focus {
  border-color: var(--primary-blue-default);
  border-top: none;
  border-left: none;
  border-right: none;
}
.mobile-especificacoes-accordion-content-container {
  background: var(--white) !important;
}
