div button.btn-vend:hover {
  transform: scale(0.98);
  transition: 0.2s;
  background-color: rgba(24, 87, 247, 0.8);
}

div button.btn-vend:focus {
  transform: scale(0.98);
  transition: 0.2s;
  border-radius: 0.5rem;
}

@media (max-width: 414px) {
  .sellers-list-wrapper {
    display: flex;
    flex-direction: column;
    background: var(--white);
    width: 100%;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 15px rgba(63, 61, 77, 0.1);
    height: min-content;
  }
  .sellers-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .sellers-list-option-container {
    display: flex;
    padding: 0;
    justify-content: left;
    gap: 8px;
    width: 100%;
    max-width: unset;
  }
  .sellers-list-option-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px 0;
  }
  .sellers-list-option-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--neutral-300);
    text-align: left;
  }
  .sellers-list-option-content {
    text-align: left;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    min-width: 100%;
    color: var(--neutral-200);
  }
  .sellers-list-option-button-left {
    width: 50px;
    height: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sellers-list-option-button-right {
    width: 50px;
    height: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sellers-list-option-button-edit-icon {
    width: 26px;
    height: 26px;
  }
}